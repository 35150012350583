let urlAddress = window.location.href;
var splitedUrlAddress = urlAddress.split(".");

var MB_URL;
var platform = localStorage.getItem("platform");

if (window.location.href.includes("mb_droid")) {
  localStorage.setItem("platform", "mb_droid");
  MB_URL = "mb_droid";
  platform = "mb_droid";
} else if (window.location.href.includes("mb_ios")) {
  localStorage.setItem("platform", "mb_ios");
  MB_URL = "mb_ios";
  platform = "mb_ios";
} else {
  if (platform) {
    MB_URL = platform;
  } else {
    MB_URL = "mb_web";
    platform = "mb_web";
  }
}

var API_MASTER,
  API_FINANCIAL,
  API_CENTRAL,
  API_REPORT,
  API_NEW_BLOG,
  API_ERP_BLOG,
  API_CLASSES,
  API_MYSCHOOLGATE,
  API_ACTIVITIES,
  API_CRM,
  API_CRM_REPORT,
  API_CVBOX,
  CVBOX_ACCESS_KEY,
  X_DTS_HOST,
  AMAZON_S3,
  isB2b,
  isDevMode,
  useFlutter,
  WS_FINANCE_TRANSPORT,
  domainName;

if (
  // Stage URL :: https://test.orchids.letseduvate.com/
  window.location.href.includes("test.orchids.letseduvate.com")
) {
  // Stage API
  API_MASTER = `https://qa.olvorchidnaigaon.letseduvate.com/${MB_URL}`;
  API_FINANCIAL = `https://orchids-stage.finance.stage-vm.letseduvate.com/${MB_URL}`;
  API_CENTRAL = `https://mgmt-stage.stage-gke.letseduvate.com/${MB_URL}`;
  API_REPORT = `https://reports.qa.letseduvate.com/${MB_URL}`;
  API_NEW_BLOG = "https://activities-ms.qa.letseduvate.com";
  API_ERP_BLOG = `https://qa.olvorchidnaigaon.letseduvate.com/${MB_URL}`;
  API_ACTIVITIES = "https://activities-ms.qa.letseduvate.com/api";
  API_CLASSES = `https://classes.qa.letseduvate.com/${MB_URL}`;
  API_MYSCHOOLGATE = `https://myschoolgate.stage-gke.letseduvate.com/${MB_URL}/`;
  API_CRM = "https://marketing.stage-gke.letseduvate.com/qbox";
  API_CRM_REPORT =
    "https://marketing-reports-stage.stage-gke.letseduvate.com/qbox/reports";
  API_CVBOX = "https://cvbox.stage-gke.letseduvate.com/qbox";
  CVBOX_ACCESS_KEY = "ntNPDkdd5NXA.Kk32anAflhbTBD4W.owdaRMIEMbveyH";
  X_DTS_HOST = "qa.olvorchidnaigaon.letseduvate.com";
  WS_FINANCE_TRANSPORT = "wss://fpythonws.stage-gke.letseduvate.com/ws";
  domainName = "olvorchidnaigaon";

  AMAZON_S3 = {
    BUCKET: "https://mgmt-cdn-stage.stage-gke.letseduvate.com",
    ERP_BUCKET: "https://acad-cdn-stage.stage-vm.letseduvate.com",
    CENTRAL_BUCKET: "https://mgmt-cdn-stage.stage-gke.letseduvate.com",
  };
  isB2b = false;
  isDevMode = false;
  useFlutter = true;
} else if (
  // Stage GCP URL :: https://orchids-stage.stage-vm.letseduvate.com/
  window.location.href.includes("orchids-stage.stage-vm.letseduvate.com")
) {
  // Stage GCP API
  API_MASTER = `https://orchids-stage.stage-vm.letseduvate.com/${MB_URL}`;
  API_FINANCIAL = `https://orchids-stage.finance.stage-vm.letseduvate.com/${MB_URL}`;
  API_CENTRAL = `https://mgmt-stage.stage-gke.letseduvate.com/${MB_URL}`;
  API_REPORT = `https://api-reports-stage.stage-gke.letseduvate.com/${MB_URL}`;
  API_NEW_BLOG = "https://activities-stage.stage-gke.letseduvate.com";
  API_ERP_BLOG = `https://orchids-stage.stage-vm.letseduvate.com/${MB_URL}`;
  API_ACTIVITIES = "https://activities-stage.stage-gke.letseduvate.com/api";
  API_CLASSES = `https://api-classes-stage.stage-gke.letseduvate.com/${MB_URL}`;
  API_MYSCHOOLGATE = `https://myschoolgate.stage-gke.letseduvate.com/${MB_URL}/`;
  API_CRM = "https://marketing.stage-gke.letseduvate.com/qbox";
  API_CRM_REPORT =
    "https://marketing-reports-stage.stage-gke.letseduvate.com/qbox/reports";
  API_CVBOX = "https://cvbox.stage-gke.letseduvate.com/qbox";
  CVBOX_ACCESS_KEY = "ntNPDkdd5NXA.Kk32anAflhbTBD4W.owdaRMIEMbveyH";
  X_DTS_HOST = "qa.olvorchidnaigaon.letseduvate.com";
  WS_FINANCE_TRANSPORT = "wss://fpythonws.stage-gke.letseduvate.com/ws";
  domainName = "olvorchidnaigaon";

  AMAZON_S3 = {
    BUCKET: "https://mgmt-cdn-stage.stage-gke.letseduvate.com",
    ERP_BUCKET: "https://acad-cdn-stage.stage-vm.letseduvate.com",
    CENTRAL_BUCKET: "https://mgmt-cdn-stage.stage-gke.letseduvate.com",
  };
  isB2b = false;
  isDevMode = false;
  useFlutter = false;
} else if (
  // Orchids Pre-prod URL :: https://orchids.prod.letseduvate.com/
  window.location.href.includes("orchids.prod.letseduvate.com")
) {
  // Orchids Pre-prod API
  API_MASTER = `https://orchids.prod.letseduvate.com/${MB_URL}`;
  API_FINANCIAL = `https://orchids.finance-prod.letseduvate.com/${MB_URL}`;
  API_CENTRAL = `https://mgmt.prod.letseduvate.com/${MB_URL}`;
  API_REPORT = `https://reports.prod.letseduvate.com/${MB_URL}`;
  API_NEW_BLOG = "https://activities.letseduvate.com";
  API_ERP_BLOG = `https://orchids.prod.letseduvate.com/${MB_URL}`;
  API_ACTIVITIES = "https://activities.letseduvate.com/api";
  API_CLASSES = `https://classes.letseduvate.com/${MB_URL}`;
  API_MYSCHOOLGATE = `https://myschoolgate.in/${MB_URL}/`;
  API_CRM = "https://marketing.letseduvate.com/qbox";
  API_CRM_REPORT = "https://marketing-reports.letseduvate.com/qbox/reports";
  API_CVBOX = "https://careerbox.letseduvate.com/qbox";
  CVBOX_ACCESS_KEY = "ntNPDkXA.KkRN.i3udsidsophiui8VVI.DFdaMMIE32d";
  X_DTS_HOST = "orchids.letseduvate.com";
  WS_FINANCE_TRANSPORT = "wss://fpythonws.letseduvate.com/ws";
  domainName = "orchids";

  AMAZON_S3 = {
    BUCKET: "https://mgmt-cdn.letseduvate.com",
    ERP_BUCKET: "https://acad-cdn.letseduvate.com",
    CENTRAL_BUCKET: "https://mgmt-cdn.letseduvate.com",
  };
  isB2b = false;
  isDevMode = false;
  useFlutter = false;
} else if (
  // B2b pre-prod URL :: https://b2b.prod.letseduvate.com/
  window.location.href.includes("b2b.prod.letseduvate.com")
) {
  // B2b pre-prod API
  API_MASTER = `https://b2b.prod.letseduvate.com/${MB_URL}`;
  API_FINANCIAL = `http://b2b.finance-prod.letseduvate.com/${MB_URL}`;
  API_CENTRAL = `https://mgmt.prod.letseduvate.com/${MB_URL}`;
  API_REPORT = `https://reports.prod.letseduvate.com/${MB_URL}`;
  API_NEW_BLOG = "https://activities.letseduvate.com";
  API_ERP_BLOG = `https://b2b.prod.letseduvate.com/${MB_URL}`;
  API_ACTIVITIES = "https://activities.letseduvate.com/api";
  API_CLASSES = `https://classes.letseduvate.com/${MB_URL}`;
  API_MYSCHOOLGATE = `https://myschoolgate.in/${MB_URL}/`;
  API_CRM = "https://marketing.letseduvate.com/qbox";
  API_CRM_REPORT = "https://marketing-reports.letseduvate.com/qbox/reports";
  API_CVBOX = "https://careerbox.letseduvate.com/qbox";
  CVBOX_ACCESS_KEY = "ntNPDkXA.KkRN.i3udsidsophiui8VVI.DFdaMMIE32d";
  X_DTS_HOST = "orchids.letseduvate.com";
  WS_FINANCE_TRANSPORT = "wss://fpythonws.letseduvate.com/ws";
  domainName = "b2b";

  AMAZON_S3 = {
    BUCKET: "https://mgmt-cdn.letseduvate.com",
    ERP_BUCKET: "https://acad-cdn.letseduvate.com",
    CENTRAL_BUCKET: "https://mgmt-cdn.letseduvate.com",
  };
  isB2b = true;
  isDevMode = false;
  useFlutter = false;
} else if (
  // Orchids URL :: https://orchids.letseduvate.com/
  window.location.href.includes("orchids.letseduvate.com")
) {
  // Orchids API
  API_MASTER = `https://orchids.letseduvate.com/${MB_URL}`;
  API_FINANCIAL = `https://orchids.finance.letseduvate.com/${MB_URL}`;
  API_CENTRAL = `https://mgmt.letseduvate.com/${MB_URL}`;
  API_REPORT = `https://reports.letseduvate.com/${MB_URL}`;
  API_NEW_BLOG = "https://activities.letseduvate.com";
  API_ERP_BLOG = `https://orchids.letseduvate.com/${MB_URL}`;
  API_ACTIVITIES = "https://activities.letseduvate.com/api";
  API_CLASSES = `https://classes.letseduvate.com/${MB_URL}`;
  API_MYSCHOOLGATE = `https://myschoolgate.in/${MB_URL}/`;
  API_CRM = "https://marketing.letseduvate.com/qbox";
  API_CRM_REPORT = "https://marketing-reports.letseduvate.com/qbox/reports";
  API_CVBOX = "https://careerbox.letseduvate.com/qbox";
  CVBOX_ACCESS_KEY = "ntNPDkXA.KkRN.i3udsidsophiui8VVI.DFdaMMIE32d";
  X_DTS_HOST = "orchids.letseduvate.com";
  WS_FINANCE_TRANSPORT = "wss://fpythonws.letseduvate.com/ws";
  domainName = "orchids";

  AMAZON_S3 = {
    BUCKET: "https://mgmt-cdn.letseduvate.com",
    ERP_BUCKET: "https://acad-cdn.letseduvate.com",
    CENTRAL_BUCKET: "https://mgmt-cdn.letseduvate.com",
  };
  isB2b = false;
  isDevMode = false;
  useFlutter = false;
} else if (
  // QA URL :: https://qa.olvorchidnaigaon.letseduvate.com/
  window.location.href.includes("qa.olvorchidnaigaon.letseduvate.com")
) {
  // QA API
  API_MASTER = `https://qa.olvorchidnaigaon.letseduvate.com/${MB_URL}`;
  API_FINANCIAL = `https://orchids-stage.finance.stage-vm.letseduvate.com/${MB_URL}`;
  API_CENTRAL = `https://mgmt-stage.stage-gke.letseduvate.com/${MB_URL}`;
  API_REPORT = `https://reports.qa.letseduvate.com/${MB_URL}`;
  API_NEW_BLOG = "https://activities-ms.qa.letseduvate.com";
  API_ERP_BLOG = `https://qa.olvorchidnaigaon.letseduvate.com/${MB_URL}`;
  API_CLASSES = `https://classes.qa.letseduvate.com/${MB_URL}`;
  X_DTS_HOST = "qa.olvorchidnaigaon.letseduvate.com";
  API_MYSCHOOLGATE = `https://myschoolgate.stage-gke.letseduvate.com/${MB_URL}/`;
  API_ACTIVITIES = "https://activities-ms.qa.letseduvate.com/api";
  API_CRM = "https://marketing.stage-gke.letseduvate.com/qbox";
  API_CRM_REPORT =
    "https://marketing-reports-stage.stage-gke.letseduvate.com/qbox/reports";
  API_CVBOX = "https://cvbox.stage-gke.letseduvate.com/qbox";
  CVBOX_ACCESS_KEY = "ntNPDkdd5NXA.Kk32anAflhbTBD4W.owdaRMIEMbveyH";
  WS_FINANCE_TRANSPORT = "wss://fpythonws.stage-gke.letseduvate.com/ws";
  domainName = "olvorchidnaigaon";

  AMAZON_S3 = {
    BUCKET: "https://mgmt-cdn-stage.stage-gke.letseduvate.com",
    ERP_BUCKET: "https://acad-cdn-stage.stage-vm.letseduvate.com",
    CENTRAL_BUCKET: "https://mgmt-cdn-stage.stage-gke.letseduvate.com",
  };
  isB2b = false;
  isDevMode = false;
  useFlutter = true;
} else if (
  // QA B2B URL :: https://qa.mit.letseduvate.com/
  window.location.href.includes("qa.mit.letseduvate.com")
) {
  // QA B2B API
  API_MASTER = `https://qa.mit.letseduvate.com/${MB_URL}`;
  API_FINANCIAL = `https://orchids-stage.finance.stage-vm.letseduvate.com/${MB_URL}`;
  API_CENTRAL = `https://mgmt-stage.stage-gke.letseduvate.com/${MB_URL}`;
  API_REPORT = `https://reports.qa.letseduvate.com/${MB_URL}`;
  API_NEW_BLOG = "https://activities-ms.qa.letseduvate.com";
  API_ERP_BLOG = `https://qa.mit.letseduvate.com/${MB_URL}`;
  API_CLASSES = `https://classes.qa.letseduvate.com/${MB_URL}`;
  X_DTS_HOST = "qa.mit.letseduvate.com";
  API_MYSCHOOLGATE = `https://myschoolgate.stage-gke.letseduvate.com/${MB_URL}/`;
  API_ACTIVITIES = "https://activities-ms.qa.letseduvate.com/api";
  API_CRM = "https://marketing.stage-gke.letseduvate.com/qbox";
  API_CRM_REPORT =
    "https://marketing-reports-stage.stage-gke.letseduvate.com/qbox/reports";
  API_CVBOX = "https://cvbox.stage-gke.letseduvate.com/qbox";
  CVBOX_ACCESS_KEY = "ntNPDkdd5NXA.Kk32anAflhbTBD4W.owdaRMIEMbveyH";
  WS_FINANCE_TRANSPORT = "wss://fpythonws.stage-gke.letseduvate.com/ws";
  domainName = "olvorchidnaigaon";

  AMAZON_S3 = {
    BUCKET: "https://mgmt-cdn-stage.stage-gke.letseduvate.com",
    ERP_BUCKET: "https://acad-cdn-stage.stage-vm.letseduvate.com",
    CENTRAL_BUCKET: "https://mgmt-cdn-stage.stage-gke.letseduvate.com",
  };
  isB2b = true;
  isDevMode = false;
  useFlutter = true;
} else if (
  // Dev URL :: https://dev.olvorchidnaigaon.letseduvate.com/
  window.location.href.includes("dev.olvorchidnaigaon.letseduvate.com")
) {
  // Dev API
  API_MASTER = `https://dev.olvorchidnaigaon.letseduvate.com/${MB_URL}`;
  API_FINANCIAL = `https://dev.erpfinance.letseduvate.com/${MB_URL}`;
  API_CENTRAL = `https://mgmt.dev-k8.letseduvate.com/${MB_URL}`;
  API_REPORT = `https://dev.reports.letseduvate.com/${MB_URL}`;
  API_NEW_BLOG = "https://activities-ms.dev-k8.letseduvate.com";
  API_ERP_BLOG = `https://dev.olvorchidnaigaon.letseduvate.com/${MB_URL}`;
  API_CLASSES = `https://dev.classes.letseduvate.com/${MB_URL}`;
  API_ACTIVITIES = "https://activities-ms.dev-k8.letseduvate.com/api";
  X_DTS_HOST = "dev.olvorchidnaigaon.letseduvate.com";
  API_MYSCHOOLGATE = `https://myschoolgate.stage-gke.letseduvate.com/${MB_URL}/`;
  API_CRM = "https://marketing.stage-gke.letseduvate.com/qbox";
  API_CRM_REPORT =
    "https://marketing-reports-stage.stage-gke.letseduvate.com/qbox/reports";
  API_CVBOX = "https://cvbox.stage-gke.letseduvate.com/qbox";
  CVBOX_ACCESS_KEY = "ntNPDkdd5NXA.Kk32anAflhbTBD4W.owdaRMIEMbveyH";
  WS_FINANCE_TRANSPORT = "wss://fpythonws.dev-k8.letseduvate.com/ws";
  domainName = "olvorchidnaigaon";

  AMAZON_S3 = {
    BUCKET: "https://mgmt-cdn-stage.stage-gke.letseduvate.com",
    ERP_BUCKET: "https://acad-cdn-stage.stage-vm.letseduvate.com",
    CENTRAL_BUCKET: "https://mgmt-cdn-stage.stage-gke.letseduvate.com",
  };
  isB2b = false;
  isDevMode = false;
  useFlutter = true;
} else if (
  // B2b URL :: https://b2b.letseduvate.com/
  urlAddress.includes("letseduvate.com") &&
  splitedUrlAddress[0]?.split("//")[1] !== "dev" &&
  splitedUrlAddress[0]?.split("//")[1] !== "qa" &&
  splitedUrlAddress[0]?.split("//")[1] !== "orchids"
) {
  // B2b API
  API_MASTER = `https://${
    splitedUrlAddress[0]?.split("//")[1]
  }.letseduvate.com/${MB_URL}`;
  API_FINANCIAL = `https://${
    splitedUrlAddress[0]?.split("//")[1]
  }.finance.letseduvate.com/${MB_URL}`;
  API_CENTRAL = `https://mgmt.letseduvate.com/${MB_URL}`;
  API_REPORT = `https://reports.letseduvate.com/${MB_URL}`;
  API_NEW_BLOG = "https://activities.letseduvate.com";
  API_ACTIVITIES = "https://activities.letseduvate.com/api";
  API_ERP_BLOG = `https://${
    splitedUrlAddress[0]?.split("//")[1]
  }.letseduvate.com/${MB_URL}`;
  API_CLASSES = `https://classes.letseduvate.com/${MB_URL}`;
  X_DTS_HOST = `${splitedUrlAddress[0]?.split("//")[1]}.letseduvate.com`;
  API_MYSCHOOLGATE = "https://myschoolgate.in/qbox/";
  API_CRM = "https://marketing.letseduvate.com/qbox";
  API_CRM_REPORT = "https://marketing-reports.letseduvate.com/qbox/reports";
  API_CVBOX = "https://careerbox.letseduvate.com/qbox";
  CVBOX_ACCESS_KEY = "ntNPDkXA.KkRN.i3udsidsophiui8VVI.DFdaMMIE32d";
  WS_FINANCE_TRANSPORT = "wss://fpythonws.letseduvate.com/ws";
  domainName = `${splitedUrlAddress[0]?.split("//")[1]}`;

  AMAZON_S3 = {
    BUCKET: "https://mgmt-cdn.letseduvate.com",
    ERP_BUCKET: "https://acad-cdn.letseduvate.com",
    CENTRAL_BUCKET: "https://mgmt-cdn.letseduvate.com",
  };
  isB2b = true;
  isDevMode = false;
  useFlutter = false;
} else {
  // Localhost
  // Stage GCP API
  API_MASTER = `https://orchids-stage.stage-vm.letseduvate.com/${MB_URL}`;
  API_FINANCIAL = `https://orchids-stage.finance.stage-vm.letseduvate.com/${MB_URL}`;
  API_CENTRAL = `https://mgmt-stage.stage-gke.letseduvate.com/${MB_URL}`;
  API_REPORT = `https://api-reports-stage.stage-gke.letseduvate.com/${MB_URL}`;
  API_NEW_BLOG = "https://activities-stage.stage-gke.letseduvate.com";
  API_ERP_BLOG = `https://orchids-stage.stage-vm.letseduvate.com/${MB_URL}`;
  API_ACTIVITIES = "https://activities-stage.stage-gke.letseduvate.com/api";
  API_CLASSES = `https://api-classes-stage.stage-gke.letseduvate.com/${MB_URL}`;
  API_MYSCHOOLGATE = `https://myschoolgate.stage-gke.letseduvate.com/${MB_URL}/`;
  API_CRM = "https://marketing.stage-gke.letseduvate.com/qbox";
  API_CRM_REPORT =
    "https://marketing-reports-stage.stage-gke.letseduvate.com/qbox/reports";
  API_CVBOX = "https://cvbox.stage-gke.letseduvate.com/qbox";
  CVBOX_ACCESS_KEY = "ntNPDkdd5NXA.Kk32anAflhbTBD4W.owdaRMIEMbveyH";
  X_DTS_HOST = "qa.olvorchidnaigaon.letseduvate.com";
  WS_FINANCE_TRANSPORT = "wss://fpythonws.stage-gke.letseduvate.com/ws";
  domainName = "olvorchidnaigaon";

  AMAZON_S3 = {
    BUCKET: "https://mgmt-cdn-stage.stage-gke.letseduvate.com",
    ERP_BUCKET: "https://acad-cdn-stage.stage-vm.letseduvate.com",
    CENTRAL_BUCKET: "https://mgmt-cdn-stage.stage-gke.letseduvate.com",
  };
  isB2b = false;
  isDevMode = true;
  useFlutter = true;
}

export {
  API_MASTER,
  API_FINANCIAL,
  API_CENTRAL,
  API_REPORT,
  API_NEW_BLOG,
  API_ERP_BLOG,
  API_CLASSES,
  API_MYSCHOOLGATE,
  API_CRM,
  API_CRM_REPORT,
  API_ACTIVITIES,
  API_CVBOX,
  CVBOX_ACCESS_KEY,
  X_DTS_HOST,
  AMAZON_S3,
  isB2b,
  isDevMode,
  useFlutter,
  WS_FINANCE_TRANSPORT,
  domainName,
  platform,
};
